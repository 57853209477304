.header-section, .footer-section {
  /* background-image: linear-gradient(to bottom right, rgba(0, 0, 0, 0), rgba(0,0,0,1)); */
  /* background-image: linear-gradient(to bottom right, white, 0.25%, black); */
  background-color: #000;
}
.header-section {
  min-height: 80px;
}
.close-button {
  font-size: 25px;
}
.letter-spacing-15 {
  letter-spacing: 1.5px;
}
.letter-spacing-1 {
  letter-spacing: 1px;
}
.line-height-50 {
  line-height: 50px;
}
.nav-link, .navbar-brand {
  color: #fff !important;
}
.nav-item .active {
  font-weight: bold;
  border-bottom: 1px solid #0A72AD;
}
.navbar-brand {
  letter-spacing: 2px;
  font-size: 20px;
  font-weight: bolder !important;
}
.navbar-brand img {
  max-width: 250px;
}
.title-section {
  color: #fff !important;
}
.title-banner h1 {
  font-size: 50px;
}
.btn-primary {
  background-image: linear-gradient(to bottom right, #399FD8, 50%, #003756);
  border: none !important;
}
.btn-primary:hover {
  background-image: linear-gradient(to bottom right, #399FD8, 80%, #003756);
}
.btn-white {
  background-color: #fff;
  color: #000;
  border-radius: 50px;
  border: none;
  font-weight: 700;
}
.btn-white:hover {
  background-color: lightgray !important;
}
.btn.btn-sec {
  color: #003756;
  font-weight: bolder;
  background-color: #f6f6f1;
}
.btn.btn-sec:hover {
  background-color: #399FD8;
  color: #fff;
}
.btn-light {
  background-color: #f6f6fa;
}
.section-seperator {
  margin-bottom: 80px;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-30 {
  width: 30%;
}
.h-33 {
  height: 33.33%;
}
.bottom-10 {
  bottom: 10px;
}
.border-prim {
  border: 1px solid #0A72AD;
}
.border-bottom-prim {
  border-bottom: 2px solid #0A72AD;
}
.flex-1 {
  flex: 1;
}
.floating-div {
  opacity: 0;
  transform: translateY(50px); /* Initially move the element down by 50px */
  transition: opacity 0.6s ease, transform 0.6s ease; /* Smooth transition */
  margin: 20px 0; /* Just to add some spacing between divs */
}

.floating-div.visible {
  opacity: 1;
  transform: translateY(0); /* Move the element to its final position */
}
.contact-us-container {
  margin: 100px 0px;
}
/* .secondary-header-section {
  min-height: 400px;
} */
/* font */
.primary-text {
  color: #0A72AD;
  font-weight: 900 !important;
}
.primary-text-white {
  color: #fff;
  font-weight: 900 !important;
}
.secondary-text {
  color: #003756;
  font-weight: 900 !important;
}
.text-dark-blue {
  color: #06354F;
}
.gradient-text {
  color: linear-gradient(to bottom right, #399FD8, 50%, #003756);;
}
.sm-font {
  font-size: 12px;
}
.md-font {
  font-size: 20px;
}
.l-font {
  font-size: 25px;
}
.xl-font {
  font-size: 35px;
}
.text-mild-muted {
  color: #C4C4C4 !important;
}

/* forms */

input, select {
  background: transparent;
  border: solid 1px #eee;
  border-radius: 5px;
  padding: 8px;
  color: #000;
}

input {
  padding: 7px;
}

/* title-marquee */

.title-marquee {
  height: 550px;
}
.slide-track {
  height: calc(200px * 16);
  display: flex;
  flex-direction: column;
  align-items: end;
  animation: scroll-bottom-up 25s linear infinite;
  justify-content: space-between;
}

.slider-2-track {
  animation: scroll-top-bottom 35s linear infinite;
}

.slide-image {
  width: 150px;
  height: 200px;
  margin: 10px 0;
  display: grid;
  place-items: center;
  transition:0.5s;
  cursor:pointer;
}
.slider::before,
.slider::after{
  position:absolute;
  content:'';
  height:100%;
  z-index:2;
  pointer-events:none;
}
.slider::before{
  top:0;
}
.slider::after{
  bottom:0;
  transform:rotateZ(180deg);
}
.slider-2::before{
  bottom:0;
}
.slider-2::after{
  top:0;
  transform:rotateZ(180deg);
}

@keyframes scroll-bottom-up {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(calc(-170px * 8));
  }
}

@keyframes scroll-top-bottom {
  0% {
    transform: translateY(calc(-170px * 8));
  }
  100% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 768px) {
  .slide-track {
    width: calc(80px * 20);
  }

  .slide-image {
    width: 80px;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-80px * 10));
    }
  }

}

/* marquee-section */
.marquee-branding {
  /* background-image: linear-gradient(to bottom right, rgba(0, 0, 0, 0), rgba(0,0,0,1)); */
  background-image: linear-gradient(to bottom right, #399FD8, 15%, #003756);
  color: #fff;
}
.experience-desc {
  font-size: 13px;
  line-height: 1.5rem;
}

/* who-we-are section */
.main-content-div {
  position: relative;
  top: 100vh;
  background-color: #fff;
  /* background-image: linear-gradient(to bottom right, #003756, 40%, #399FD8); */
}
.who-we-are {
  gap: 25px;
  justify-content: center;
}
.who-we-are > div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #0A72AD;
  border-radius: 20px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .16), 0 0 20px 0 rgba(0, 0, 0, .06);
}
.who-we-are-card {
  color: white;
  overflow: hidden;
  height: 450px;
  border-radius: 20px;
  background-color: #0b4261;
}
.about-us-content {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 400px; */
}
.about-us-img {
  width: 50% !important;
  right: -1px;
  top: -1px;
  height: 100%;
  width: 100%;
}
.about-us-img img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-position: center;
}
.about-us-col-img {
  display: none;
}
.who-we-are-img {
  background-image: url(../public/proj-1-7.jpg);
  height: 300px;
  background-size: 140% 150%;
  width: 450px;
  background-position: -10px -20px;
}
.property-container .carousel-control-next,
.property-container .carousel-control-prev {
  display: none;
}

/* country card */
/* .country-card {
  width: 350px;
  height: 550px;
} */
.home-carousel .carousel-indicators [data-bs-target] {
  width: auto !important;
  height: auto !important;
  text-indent: 0 !important;
  background-color: transparent !important;
  transition: all .6s ease-in-out;
  opacity: 1 !important;
}
.home-carousel .carousel .carousel-indicators .carousel-control-prev-nav {
  background: black !important;
  opacity: 0.5 !important;
  border-radius: 50%;
  width: 30px !important;
  height: 10px !important;
  padding: 0 !important;
  margin: 0 !important;
}
.home-carousel .carousel-control-slide {
  background-color: black !important;
  border-radius: 50%;
  width: 30px !important;
  height: 30px !important;
}
/* .home-carousel .carousel-control-prev-icon, .carousel-control-next-icon {
  width: 10px !important;
  height: 10px !important;
} */
.home-carousel .carousel-control-prev-nav span {
  width: 10px !important;
  height: 10px !important;
}
.home-carousel .carousel-card {
  border-radius: 20px;
  transition: all .2s ease-in-out;
}
.home-carousel .carousel-card img {
  border-radius: 20px;
}
.home-carousel .carousel-card:hover {
  transform: scale(1.05);
  border: #399FD8 solid 2px;
}
.country-nav {
  list-style: none;
  font-weight: bold;
  margin: 0 !important;
}
.country-nav .active {
  color: #0A72AD;
  border-bottom: 1px solid #0A72AD !important;
  transform: scale(1.2);
}
.countries-split-nav {
  margin: 10px 10px !important;
}
.country-nav-title {
  padding: 0 15px;
}
.country-card-title-bg {
  background: #0A72AD;
  opacity: 0.6;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.country-card-title {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

/* cx testimonial */
.cx-testimonial {
  width: 100%;
  background-image: linear-gradient(to bottom right, #399FD8, 50%, #003756);
  height: 500px;
}
.testimonail-cards {
  height: 350px;
}
.testimonial-header {
  font-size: 32px;
  color: white;
  font-weight: bolder;
  text-align: center;
}
.carousel-testimonial-next-icon {
  background-color: #003756;
}
.our-wall-love {
  font-weight: 900;
  color: gold;
}
.customer-review-desc {
  font-size: 18px;
}
.customer-review-profile {
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 2px;
  font-style: italic;
}
.icon-lg {
  width: 50px;
  height: 50px;
}
.carousel-indicators button {
  width: 50px !important;
  height: 55px !important;
  position: relative !important;
  margin-right: 50px !important;
  background-color: transparent !important;
}
.carousel-indicators button.active {
  transform: scale(1.5);
}
.carousel-indicators button img {
  opacity: 0.5;
}
.carousel-indicators button.active img {
  opacity: 1;
}
.carousel-indicators button img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.customer-img {
  width: 300px;
  position: absolute;
  left: 15%;
  bottom: 15%;
}
.customer-review {
  color: #fff;
  border-radius: 20px;
  height: fit-content;
  z-index: 2;
  margin: 20px;
}
.customer-review div {
  color: #fff;
}
.cx-testimonial .carousel-control-prev,
.cx-testimonial .carousel-control-next {
  z-index: 3;
}

/* Home -2 css */
.home-page-video {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  max-width: 100%;
  object-fit: cover;
}
.home-video-div:after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
}
.home-page-title {
  font-family: Verdana, sans-serif;
  font-weight: 900;
}
.home-page-sec-title {
  font-size: 30px;
  letter-spacing: 2px;
  word-spacing: 5px;
  font-weight: 400;
}
.header-section .slide-nav-bar {
  top: -75px;
}
.navbar {
  z-index: 999;
}
.navbar-collapse.show {
  background-color: #000;
}
.navbar-toggler-icon {
  background-image: url(../public/pngs/stack.png) !important;
}
.fixed-nav {
  position: fixed;
  top: 0;
  transition: all 0.8s ease-in-out;
  background-color: #000;
  z-index: 999;
}
.homepage-video-section, .property-page-header .secondary-page-header {
  position: fixed;
  width: 100%;
  height: 100vh;
}
.title-banner {
  width: 80%;
  position: absolute;
  left: 50%;
  top: 45%;
  margin-left: -40%;
  color: #fff;
  font-weight: bolder;
  text-align: center;
}

/* slick-slider  */
.country-card-container {
  width: 80%;
  height: 90%;
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}
.slick-slide > div > div {
  height: 480px;
}
.slick-center a {
  padding: 10px;
  border: 5px solid #399FD8;
}
.slick-center > div > div {
  transform: scale(1.08);
  transition: all .5s ease-in-out;
  transition-delay: .5s;
}
/* .slick-slide {
  opacity: 0.7;
} */
/* .slick-slide.slick-center > div > div {
  opacity: 1;
} */
.slick-slide > div > div {
  transition: all .5s ease-in-out;
  transition-delay: .5s;
}
.nav-slider-class .slick-slide {
  font-weight: bold;
}
.country-nav-title {
  cursor: pointer;
}
.nav-slider-class .slick-center .country-nav-title {
  color: #0A72AD;
  border-bottom: 2px solid #003756;
}
.nav-slider-class .slick-slide {
  padding: 0 10px;
  text-align: center;
}
/* .country-card-slider .slick-arrow {
  display: none !important;
} */
.slick-prev:before, .slick-next:before  {
  color: #000 !important;
}
.filter-applied {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #399FD8;
  display: inline-block;
}

/* our projects */
.projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  grid-auto-rows: minmax(100px, auto);
}
.projects-container .carousel-control-next-icon {
  transition: all 0.4s ease-in-out;
}
.projects-container .projects-card:hover .carousel-testimonial-next-icon {
  background-color: #0A72AD;
}
.projects-container .projects-card:hover .carousel-control-next-icon {
  transform: translateX(10px);
}
/* .projects-card {
  wi
} */
.projects-card{
  width: 300px;
  height: 380px;
  margin-left: auto;
  margin-right: auto;
}
.projects-card-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
}

/* projects card accordion */
.projects-section {
  background-color: #f7f7f7;
  border-radius: 30px;
}
#projects-container-accordion {
  display: flex;
  align-items: start;
  justify-content: center;
}
.projects-images {
  width: 50%;
}
.projects-images img {
  max-height: 400px;
}
.projects-list {
  width: 35%;
}
.projects-title {
  color: #0A72AD !important;
  font-weight: bold !important;
  font-size: 18px !important;
  padding: 10px 0px 10px 0!important;
  border-bottom: 1px solid lightgray !important;
  border-radius: 0 !important;
  width: 100% !important;
  text-align: left !important;
}
.projects-items-desc {
  margin-top: 10px;
  border-bottom: 1px solid lightgray;
  visibility: hidden;
  max-height: 0;
  opacity: 0;
}
.projects-desc {
  display: none;
  visibility: hidden;
  max-height: 0;
  opacity: 0;
}
.projects-items .projects-items-desc,
.projects-uae .projects-uae-desc,
.projects-sobha .projects-sobha-desc,
.projects-istanbul .projects-istanbul-desc,
.projects-saudi .projects-saudi-desc {
  visibility: visible;
  display: block;
  opacity: 1;
  overflow: hidden;
  max-height: 285px;
  padding: 10px 8px 20px;
  transition: max-height 1s;
}
.projects-items .projects-items-image,
.projects-uae .projects-uae-image,
.projects-sobha .projects-sobha-image,
.projects-istanbul .projects-istanbul-image,
.projects-saudi .projects-saudi-image {
  visibility: visible;
  opacity: 1;
  height: auto;
  overflow: hidden;
  transition: opacity 1s;
}
.projects-image {
  visibility: hidden;
  height: 0;
  opacity: 0;
}

/* details page */
.property-img {
  transition: all 0.4s ease-in-out;
}
.property-container:hover .property-img {
  transform: scale(1.05);
}
.propety-details-container {
  grid-template-columns: 15fr 7fr;
}
.propety-details-container .project-about-details {
  font-size: 18px;
  margin-bottom: 40px !important;
}
.property-second-img {
  border-radius: 40px;
  margin: 35px 0px;
}
.secondary-img-sec {
  width: 80%;
  margin: auto;
}
.property-features {
  margin-right: 80px;
}
.feature-img-slider.hide-arrows .carousel-control-next ,
.feature-img-slider.hide-arrows .carousel-control-prev {
  display: none;
}
.location-iframe iframe {
  width: 100%;
}
.details-card {
  /* box-shadow: 0 0 2px 0 rgba(0, 0, 0, .16), 0 0 20px 0 rgba(0, 0, 0, .06); */
  border-radius: 20px;
  padding: 30px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 20px;
}
.details-card-item {
  padding: 10px;
  border: 1px solid #0A72AD;
  margin-bottom: 10px;
  position: relative;
}
.details-card-item:before {
  content: "";
  width: 80px;
  height: 10px;
  position: absolute;
  top: 0;
  background: #ffff;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.details-card-item-icons {
  margin: -30px auto 5px;
  height: 40px;
  width: 40px;
  z-index: 2;
}
.side-bar-features {
  position: sticky;
  top: 75px;
}
.features-container-card {
  margin: 60px;
  padding: 20px 0;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .16), 0 0 20px 0 rgba(0, 0, 0, .06);
  border-radius: 5px;
}
.interested-in-property {
  background-color: #003756;
  color: #fff;
}
.feature-card-li {
  padding: 10px;
  border: 1px solid #eee;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
  color: #003756;
  font-weight: 500;
}
.feature-card-li:hover {
  background-color: #003756;
  color: #fff;
}

/* property card */
.property-card {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 60px;
}
.property-card li {
  list-style: none;
}
.property-card li a {
  text-decoration: none;
}
.property-card .carousel-inner {
  border-radius: 10px;
}

/* image grid view */
.img-grid-view {
  height: 600px;
}
.img-grid-view .more-images>img {
  opacity: 0.4;
}
.img-grid-view .more-images {
  position: relative;
}
.img-grid-view .expand-images {
  position: absolute;
  top: 29%;
  left: 33%;
  padding: 20px;
  border-radius: 50%;
  background-color: #000;
  color: #fff;
  font-size: 25px;
  background-color: #000;
  opacity: 0.5;
}
.images-expand-modal {
  padding: 50px !important;
  padding-top: 10px !important;
}
.img-expanded-preview .slick-arrow {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  background-color: #fff;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .16), 0 0 20px 0 rgba(0, 0, 0, .06);
  z-index: 2;
}
.slick-prev:before, .slick-next:before {
  font-family: inherit !important;
  font-size: 35px !important;
}
.img-expanded-preview .slick-arrow:hover.slick-arrow::before {
  color: #fff !important;
}
.img-expanded-preview .slick-arrow:hover {
  background-color: #0A72AD !important;
}
.img-grid-view > div {
  cursor: pointer;
}

/* --------- contact us -------- */
.contact-us-modal {
  padding: 50px !important;
  padding-top: 10px !important;
  background-color: #003756;
  color: #fff;
}
.contact-us .contact-us-modal input,
.contact-us .sell-form-container input {
  padding: 10px 38px;
}
.contact-us .contact-us-input-div::after {
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  content: "";
  position: absolute;
  top: 17px;
  left: 11px;
}
.contact-us-submit {
  background-color: #399FD8 !important;
  color: #fff !important;
  letter-spacing: 1.5px;
  font-size: 14px !important;
}
.contact-us-submit:hover {
  background-color: #0A72AD !important;
}
.react-tel-input .form-control {
  height: auto !important;
  width: inherit !important;
}
.user-name-field::after {
  background-image: url(../public/svgs/user.svg);
}
.email-field::after {
  background-image: url(../public/svgs/email.svg);
}
.property-location::after {
  background-image: url(../public/svgs/city.svg);
  background-size: contain;
}
.property-country::after {
  background-image: url(../public/svgs/country.svg);
  background-size: contain;
}
.flag-dropdown {
  background-color: #fff !important;
  border-right: none !important;
}
.react-tel-input .country-list .search {
  padding-right: 6px !important;
}
.react-tel-input .country-list .country {
  color: #000;
}
.selected-flag.open {
  border: #CACACA !important;
}
.search-emoji {
  display: none !important;
}
.flag-dropdown .search-box {
  width: 100% !important;
  padding: 10px !important;
  margin: 0 !important;
  color:  #000 !important;
}

/* contact us page */
.contact-us-card {
  padding: 20px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .16), 0 0 20px 0 rgba(0, 0, 0, .06);
  border-radius: 5px;
  background-color: #DEEFF5;
}

/* sell form container */
.sell-form-container {
  padding: 20px 50px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .16), 0 0 20px 0 rgba(0, 0, 0, .06);
  width: 80%;
  margin: auto;
}

/* footer */

.footer-section {
  color: #fff;
}
.footer-section .widget-title {
  margin-bottom: 15px;
}
.footer-section #menu-quick-links {
  padding: 0 !important;
}
.footer-section #menu-quick-links li,
.footer-section .textwidget {
  list-style: none;
  margin-bottom: 10px;
  font-size: 15px;
}
.footer-section .textwidget div,
.footer-section .textwidget p {
  color: rgba(255, 255, 255, .6) !important;
}
.footer-section #menu-quick-links li a {
  text-decoration: none;
  color: rgba(255, 255, 255, .6);
}
.footer-section #menu-quick-links li a:hover {
  color: #fff
}
.footer-section .social-icons {
  width: 40px;
  height: 40px;
}

/* secondary page */
.secondary-page-header {
  position: relative;
}
.property-page-header .secondary-page-header .bg-image {
  height: 800px;
  object-fit: contain;
}
.secondary-page-header .bg-image {
  height: 450px;
  object-fit: contain;
  background-size: cover;
}
.secondary-page-header .bg-image {
  background-position: none;
}
.secondary-page-header .bg-image::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
}
.property-page-header .secondary-page-header .overlay-text {
  top: 40%;
}
.secondary-page-header .overlay-text {
  position: absolute;
  top: 45%;
  text-align: center;
  width: 100%;
  color: #fff;
}
.secondary-page-header .page-title {
  font-size: 65px !important;
  font-weight: 700;
  letter-spacing: 3px;
  margin-bottom: 10px;
}
.secondary-page-header .page-path {
  font-size: 20px;
  font-weight: 700;
  word-spacing: 10px;
  letter-spacing: 3px;
}
.property-page-header .secondary-page-header .page-path {
  font-size: 16px;
  font-weight: 700;
  word-spacing: 10px;
  letter-spacing: 1.5px;
}
.nav-bar-bg::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

/* -------------------------------------------------------------------- */

@media screen and (max-width: 1200px) {
}
@media screen and (max-width: 1000px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .projects-container div:last-child {
    grid-column: 1 / -1;
    justify-self: center;
  }
  .who-we-are-img {
    width: 300px;
    height: 250px;
  }
  .list-property {
    flex-direction: column;
  }
  .list-property-img {
    display: none;
  }
  .list-property-img {
    width: 100%;
  }
  .customer-img {
    display: none;
  }
  .customer-review {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 1024px) {
  .list-property-img img {
    width: 80%;
    padding-left: 40px;
  }
  .about-us-img img {
    object-position: -50px;
  }
  .property-card {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media screen and (max-width: 768px) {
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .projects-card h6{
    font-size: 14px;
  }
  .projects-card span{
    font-size: 12px;
  }
  .projects-content .projects-desc {
    max-height: 400px;
  }
  .slick-slide > div > div {
    height: 380px;
  }
  .testimonial-header {
    font-size: 25px;
  }
  .list-property .list-property-desc {
    width: 100% !important;
  }
  .about-us-img {
    display: none;
  }
  .who-we-are-card {
    height: 750px;
    padding: 0 !important;
    margin: auto;
    width: 80% !important;

  }
  .who-we-are-card .who-we-are-card-flex {
    padding: 0 !important;
  }
  .who-we-are-card .about-us-content {
    width: 80%;
    margin: auto !important;
    padding: 0 !important;
    padding-top: 10px !important;
  }
  .about-us-col-img {
    display: block;
  }
  .about-us-col-img img {
    height: 400px;
    width: 100%;
  }
  .see-more-link {
    text-align: center !important;
    display: block;
    margin-top: 20px;
  }
  .feedback-text {
    font-size: 20px;
    line-height: 35px;
  }
  .who-we-are-card-flex {
    flex-direction: column;
  }
  /* .about-us-img {
    display: none;
  } */
  .cx-testimonial {
    height: 500px;
  }
  .testimonail-cards {
    height: 400px;
  }
  .cx-testimonial .customer-review {
    margin-bottom: 0px !important;
  }
  .cx-testimonial .carousel-control-prev {
    left: -5px;
  }
  .cx-testimonial .carousel-control-next {
    right: -5px;
  }
  .property-card {
    width: 80%;
    margin: auto;
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .contact-us-card div {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .contact-us-card div button {
    padding: 20 !important;
    margin-top: 20px;
  }
  .who-we-are-sell {
    flex-direction: column;
  }
  .who-we-are-sell div {
    width: 90%;
  }
}
@media screen and (max-width: 500px) {
  .property-card {
    width: 100%;
  }
  .buy-search-bar {
    flex-direction: column;
    gap: 20px;
  }
  .buy-page-results-header {
    flex-direction: column;
    gap: 20px;
  }
  .vertical-seperator {
    border-top: 1px solid #eee;
  }
  .buy-search-btn {
    width: 50%;
    margin: auto;
  }
  .navbar-brand img {
    max-width: 180px;
  }
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .title-banner .home-page-header h1 {
    font-size: 35px;
  }
  .homepage-video-section .title-banner .home-page-sec-title {
    font-size: 17px;
  }
  .projects-content .projects-desc {
    max-height: 650px;
  }
  .about-us-col-img img {
    height: 300px;
    width: 100%;
  }
  .who-we-are-card {
    height: 850px;
  }
  .xl-font {
    font-size: 25px;
  }
  .who-we-are-banner .who-we-are {
    flex-wrap: wrap;
  }
  .country-card-slider .slick-prev {
    left: 0;
    z-index: 9;
  }
  .country-card-slider .slick-next {
    right: 0;
  }
  .cx-testimonial {
    height: 600px;
}
  .testimonail-cards {
    height: 500px;
  }
  .carousel-indicators button {
    width: 30px !important;
    height: 35px !important;
  }
  .projects-images {
    width: 0;
  }
  .projects-image {
    display: none;
  }
  .projects-list {
    width: 90%;
  }
  .projects-list img {
    display: block !important;
    max-height: 250px;
  }
  .feature-section-details .feature-img-slider  {
    width: 80% !important;
  }
  .property-features {
    margin: 0 !important;
  }
  .secondary-page-header .page-title {
    font-size: 30px !important;
    margin: 20px;
  }
  .feature-section-details div {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
  }
  .feature-section-details .features-section-content {
    width: 80% !important;
  }
  .property-page-header .secondary-page-header .overlay-text {
    top: 25%;
  }
  .contact-us-btns {
    flex-direction: column;
  }
  .contact-us-btns button {
    margin: 0 !important;
    margin-bottom: 20px !important;
  }
}

/* buy page */
.buy-search-bar {
  border: 1px solid #eee;
  border-radius: 10px !important;
  padding: 10px 15px 5px 15px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .16), 0 0 20px 0 rgba(0, 0, 0, .06);
}
.buy-search-bar .area-field {
  border: none;
}
.property-type-popover {
  padding: 0px 0px;
  margin: 0;
}
.property-type-popover li {
  list-style: none;
  font-size: 16px;
  padding: 10px 15px 10px 40px;
  width: 200px;
  text-align: start;
  cursor: pointer;
  position: relative;
}
.property-type-popover li:hover {
  background-color: #0A72AD;
  color: #fff;
  font-weight: bold;
}
.vertical-seperator {
  border-right: 1px solid #eee;
}
.search-bar-btn {
  border: none;
  background-color: transparent;
  margin: 5px 10px;
}
.search-bar-btn:hover {
  background-color: #eee;
  border-radius: 10px;
}
.property-type-popover.react-tel-input .flag {
  position: absolute;
  top: 39%;
  left: 15px;
}
.coutry-globe-icon {
  width: 30px;
  height: 30px;
}
.country-search-bar .react-tel-input .flag {
  position: relative !important;
}
.area-search-autocomplete .MuiFormControl-marginNormal {
  margin: 0 !important;
}
.area-search-autocomplete fieldset {
  border-radius: 50px;
}
.area-search-autocomplete legend {
  float: none !important;
}
.social-form-circle {
  border-radius: 50%;
  border: 1px solid #0A72AD;
}
.social-form-circle:hover {
  background-color: #0b4261;
}
.social-form-circle:hover a {
  color: #fff;
}

/* // loading component */
.loading-comp-img {
  height: 300px;
  width: 300px;
  border: 1px solid #eee;
  border-radius: 30px;
  background-color: #efefef;
}

.loading-comp {
  width: 280px;
  height: 220px;
  border-radius: 6px;
  box-shadow: 0 10px 45px rgba(0, 0, 0, .2);
  background-repeat: no-repeat;
  
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmering;
  animation-timing-function: linear;

  background: linear-gradient(45deg, rgba(226,226,226,1) 9%, rgba(238,238,238,1) 18%, rgba(226,226,226,1) 31%);
  
  background-size: 1300px 100%;

}
.loading-comp-txt {
  width: 150px;
  height: 25px;
  background: linear-gradient(45deg, rgba(226,226,226,1) 9%, rgba(238,238,238,1) 18%, rgba(226,226,226,1) 31%);
  background-size: 1300px 100%;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmering;
  animation-timing-function: linear;
}

.page-skeleton-loader {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-x: hidden;
}

.page-skeleton-txt {
  background-color: #000;
  opacity: .3;
  border-radius: 40px;
}

.loading-comp-bg-img {
  width: 100%;
  height: 80vh !important;
  background-color: black;
  opacity: .7;
}

.loading-comp-desc {
  width: 70%;
  height: 20px;
  background-color: #000;
  opacity: .3;
  border-radius: 40px;
}

@keyframes shimmering {
  0% {
      background-position: -1300px 0;
  }
  100% {
      background-position: 1300px 0;
  }
}